import { compact } from 'lodash'
import moment from 'moment-timezone'
import { OptionType } from '../types/OptionType'
import { sanitizeValue } from './utils'

/**
 * A filter that is pre-applied to the UI and ES query.
 * key - Column name, a.k.a, attribute. Eg. `patientStatus`
 * value - The value to filter on. Eg. `Active`
 * operator - The operator to use for the filter. Eg. `exactly`
 */
export type PredefinedFilter = Record<string, { value?: any; operator: FilterOperator }>

/**
 * @type - NoDefaultValueTypes. FilterValueTypes that do not require a default value. Eg. FilterValueType.string
 */
type NoDefaultValueTypes = keyof { [K in FilterValueType]: K extends FilterValueType.rangeDate ? never : K }
/**
 * @type TypeDefaultValue. Filter that require a default value and other props to work correctly. Eg. FilterValueType.rangeDate
 * @type type - The type of the filter. If FilterValueType.rangeDate is used then dateOperator or defaultValue is required.
 * @type defaultValue - The default value to use for the filter.
 * @type dateOperator - The default date operator to use for the filter. If DateRangeOperator.all is used then defaultValue is optional.

 */
type TypeDefaultValue =
  | {
      type: NoDefaultValueTypes
      defaultValue?: string | string[]
      dateOperator?: DateRangeOperator
    }
  | {
      type: FilterValueType.rangeDate
      defaultValue: string | string[]
      dateOperator?: Exclude<DateRangeOperator, DateRangeOperator.all>
    }
  | {
      type: FilterValueType.rangeDate
      dateOperator: DateRangeOperator.all
      defaultValue?: string | string[]
    }
/**
 * @type DateRangeSelectorOptions. Options for the date range selector.
 * @type includeAll - If true, the filter will include All selector for dates in the date range.
 * @type includeToday - If true, the filter will include Today selector in the date range.
 * @type includeYesterday - If true, the filter will include Yesterday selector in the date range.
 * @type includeWeeks - If true, the filter will include includeWeeks in the date range.
 * @type limitToMonth - If true, the filter will limit the date range to months.
 */
type DateRangeSelectorOptions = {
  includeAll?: boolean
  includeToday?: boolean
  includeYesterday?: boolean
  includeWeeks?: boolean
  limitToMonth?: boolean
}

/* eslint-disable quotes */
export type FilterObject = {
  attribute: string
  secondaryAttribute?: string
  displayName: string
  operator: FilterOperator
  value?: string | string[]
  secondaryValue?: string[]
  options?: OptionType[]
  static?: boolean
  uniqOperator?: FilterOperator
  readOnly?: boolean
  nonEmpty?: boolean
  optionsMapper?: (response: any) => OptionType[]
} & TypeDefaultValue &
  DateRangeSelectorOptions

export enum FilterValueType {
  'string',
  'id',
  'number',
  'rangeDate',
  'date',
  'boolean',
  'multiple',
  'stringArray',
  'age',
  'readOnly',
  'assignedTo',
  'attributeExists',
  'pmsIntegration',
  'select',
}

export type FilterValue = string | string[] | undefined
/**
 * @type attribute - The name of the attribute to filter on. Eg. `patientStatus`
 * @type secondaryAttribute - It is used for filters that filter by attribute but show a different attribute in the UI. Eg. `assignedTo` but show `usedId` in the UI.
 * @type displayName - The name of the attribute to display in the UI. Eg. `Contact Status`
 * @type readOnly - If true, a filter can not change its operator and value. E.g `My Assigned Contacts`.
 * @type defaultFilter - If true, a filter came from the loader and should be rendered in the UI with pre-applied values.
 * @type customSearchParam - URL search param to use for the loader filter. Eg. `channel` or `toDate`.
 * @type queryByName - Query by the name of the lead owner instead of the id Eg. `leadOwner.name`.
 * @type defaultOperator - The default operator to use for the filter.
 * @type uniqOperator - If set, the filter will be unique in the UI and a user can not change the operator.
 * @type optionsSource - If set, API endpoint to search for filter values. Eg. `/api/locations/`
 * @type contextualFiltering - If set, the filter will hear for a pre-applied filter and restrict possible options.
 * It translates from a filter.attribute to a specific model field Eg. `{ utmAccountId: 'accountId' }`.
 * @type additionalParams - Additional params to be added to the API endpoint. Eg. `{ workspaceId: '1' }`
 * @type options - To be used with dropdown filters.
 * @type value - Takes precedence over defaultValue when it was set by the user. It is used in deep linking.
 * @type secondaryValue - It is used in deep linking.
 * @type operator - Takes precedence over defaultOperator when it was set by the user. It is used in deep linking.
 * @type nonEmpty - If true, the filter will be included in the ES querying any not empty value. Eg. utmCampaing:"*".
 **/
export type FilterableColumn = {
  attribute: string
  secondaryAttribute?: string
  displayName: string
  readOnly?: boolean
  defaultFilter?: boolean
  customSearchParam?: string | string[]
  queryByName?: boolean
  defaultOperator?: FilterOperator
  uniqOperator?: FilterOperator
  optionsSource?: string
  contextualFiltering?: Record<string, string>
  additionalParams?: { [key: string]: string }
  options?: OptionType[]
  value?: string | string[]
  secondaryValue?: string[]
  operator?: FilterOperator
  nonEmpty?: boolean
  optionsMapper?: (response: any) => OptionType[]
} & TypeDefaultValue &
  DateRangeSelectorOptions

export const isEmptyFilter = (type: FilterValueType, value: FilterValue) =>
  (type === FilterValueType.id || type === FilterValueType.stringArray || type === FilterValueType.multiple) &&
  (typeof value === 'undefined' || !value || compact(value).length === 0)

export const getAllowedOperators = (valueType: FilterValueType): FilterOperator[] => {
  switch (valueType) {
    case FilterValueType.id:
    case FilterValueType.stringArray:
    case FilterValueType.multiple:
      return [`in`, `not_in`]
    case FilterValueType.string:
      return [`contains`, `not_contains`, `exactly`]
    case FilterValueType.rangeDate:
      return [`between`]
    case FilterValueType.number:
    case FilterValueType.age:
      return [`exactly`, `greater_than`, `lesser_than`, `between`]
    case FilterValueType.readOnly:
    case FilterValueType.pmsIntegration:
      return [`exactly`]
    case FilterValueType.boolean:
      return [`exactly`, `not_exactly`]
    case FilterValueType.attributeExists:
      return [`attribute_exists`]
    case FilterValueType.date:
      return [`greater_equal_than`, `exactly`, `not_contains`]
    case FilterValueType.select:
      return [`attribute_exists`, `attribute_not_exists`]
    default:
      return [`contains`]
  }
}
/* eslint-disable-line quotes */
export enum DateRangeOperator {
  'custom',
  'yesterday',
  'today',
  'this_week',
  'last_week',
  'this_month',
  'last_month',
  'this_year',
  'last_year',
  'all',
}

export type FilterOperator =
  | `in`
  | `not_in`
  | `greater_than`
  | `greater_equal_than`
  | `lesser_than`
  | `between`
  | `contains`
  | `not_contains`
  | `exactly`
  | `not_exactly`
  | `attribute_exists`
  | `attribute_not_exists`

export function getReadableOperator(op: FilterOperator, type: FilterValueType): string {
  switch (op) {
    case 'in':
      return `includes`
    case 'not_in':
      return 'do not include'
    case 'not_contains':
      return type === FilterValueType.date ? `≠` : `does not include`
    case 'greater_than':
      if (type === FilterValueType.number || type === FilterValueType.age) {
        return 'greater than'
      } else if (type === FilterValueType.date) {
        return `>`
      }
      return 'after'
    case 'greater_equal_than':
      return `≥`
    case 'lesser_than':
      return type === FilterValueType.number || type === FilterValueType.age ? 'less than' : 'before'
    case 'contains':
      return 'includes'
    case 'exactly':
      return type === FilterValueType.date ? `=` : `is`
    case 'not_exactly':
      return `is not`
    case 'attribute_exists':
      return 'is'
    case 'attribute_not_exists':
      return 'is not'
    case `between`:
      return type === FilterValueType.rangeDate ? `is` : `between`
  }
}

export function getReadableValue(value: FilterValue, type: FilterValueType, operator: FilterOperator): string {
  if (typeof value === 'undefined' || !value || compact(value).length === 0) {
    return ''
  }
  if (
    (type === FilterValueType.number || type === FilterValueType.age) &&
    operator !== `between` &&
    typeof value === `string`
  ) {
    return value.replace(/'/g, ``).split(`,`)[0]
  }
  if (Array.isArray(value)) {
    if (operator === `between`) {
      return value.join(` and `)
    }
    return value.length > 1 ? `(${value.length})` : ``
  }
  if (type === FilterValueType.boolean) {
    switch (value) {
      case `true`:
        return `Active`
      case `false`:
        return `Inactive`
      default:
        return value
    }
  }
  return value
}

export function encodeFilters(filters: FilterObject[]): string {
  return filters
    ?.map(
      (filter) =>
        `${filter.attribute} ${filter.operator} ${
          Array.isArray(filter.value)
            ? `(${filter.value?.map((val) => `'${val}'`).join(`,`) ?? ``})`
            : `'${filter.value ?? ``}'`
        }`,
    )
    .join(`|`)
}

export function decodeFilters(filterFromUrl: string | null | undefined): FilterObject[] {
  const getPartsFromFiltersString = (filters: string) => {
    const indexOfFirstSpace = filters.indexOf(' ')
    const indexOfSecondSpace = filters.indexOf(' ', indexOfFirstSpace + 1)
    const attribute = filters.slice(0, indexOfFirstSpace)
    const operator = filters.slice(indexOfFirstSpace + 1, indexOfSecondSpace) as FilterOperator
    const valueSlot = filters.slice(indexOfSecondSpace + 1)
    return [attribute, operator, valueSlot]
  }
  if (!filterFromUrl) {
    return []
  }
  return filterFromUrl.split(`|`).map((filter) => {
    const [attribute, operator, valueSlot] = getPartsFromFiltersString(filter)
    if (operator === `in` || operator === `not_in` || operator === `between`) {
      const value = valueSlot
        .slice(1, valueSlot.length - 1)
        .split(`,`)
        ?.map((wrappedVal) => wrappedVal.slice(1, wrappedVal.length - 1))
      return { attribute, operator, value } as FilterObject
    }
    return { attribute, operator, value: valueSlot.slice(1, valueSlot.length - 1) } as FilterObject
  })
}
export function convertFilterToESQueryString(filter: FilterObject): string {
  let sanitizedValue = sanitizeValue(filter.value)
  // NOTE: this contains a hack to make the attribute_exists operator work with the way we're using it.
  // If the value is false, we want to negate the es query so that it returns objects that don't have the attribute.
  const base = `${
    filter.operator.includes(`not`) || (filter.operator === 'attribute_exists' && sanitizedValue === 'false') ? `!` : ``
  }${filter.attribute}:`
  if (filter.attribute === `birthDate`) {
    if (filter.operator === `between`) {
      sanitizedValue = (sanitizedValue as Array<string>).map((value, i) => {
        return moment()
          .subtract(+value + i, 'years')
          .format('YYYY-MM-DD')
      })
    } else {
      let difference = filter.operator === `greater_than` ? 1 : 0
      sanitizedValue = moment()
        .subtract(+sanitizedValue + difference, 'years')
        .format('YYYY-MM-DD')
    }
  }
  if (
    sanitizedValue.length === 0 ||
    sanitizedValue === `` ||
    !sanitizedValue ||
    (Array.isArray(sanitizedValue) && sanitizedValue.every((val) => val === ''))
  ) {
    if (filter.operator.includes(`not`)) {
      return `NOT _exists_:${filter.attribute}`
    } else return ''
  }
  const opAndValue: string = (() => {
    switch (filter.operator) {
      case `contains`:
      case `not_contains`:
        return sanitizedValue.includes(` `) ? `"${sanitizedValue}"` : `*${sanitizedValue}*`
      case `greater_than`:
        return `${filter.attribute === 'birthDate' ? '<' : '>'}${sanitizedValue}`
      case `greater_equal_than`:
        return `>=${sanitizedValue}`
      case `lesser_than`:
        return `${filter.attribute === 'birthDate' ? '>' : '<'}${sanitizedValue}`
      case `between`:
        if (filter.attribute === `birthDate`) return `[${sanitizedValue[1]} TO ${sanitizedValue[0]}]`
        if (moment.isDate(sanitizedValue[0])) {
          return `[${moment(sanitizedValue[0]).format('YYYY-MM-DD')} TO ${moment(sanitizedValue[1]).format(
            'YYYY-MM-DD',
          )}]`
        }
        return `[${sanitizedValue[0]} TO ${sanitizedValue[1]}]`
      case `exactly`:
        return filter.attribute === 'birthDate'
          ? `>${moment(sanitizedValue, 'YYYY-MM-DD')
              .subtract(1, `year`)
              .format(`YYYY-MM-DD`)} AND ${base}<${sanitizedValue}`
          : `"${sanitizedValue}"`
      case `in`:
      case `not_in`:
        return `${
          Array.isArray(sanitizedValue) && sanitizeValue.length
            ? `(${sanitizedValue
                ?.map((val) => (val === `(NOT _exists_:${filter.attribute})` ? val : val === `*` ? val : `"${val}"`))
                .join(` OR `)})`
            : ``
        }`
      case `attribute_exists`:
      case `attribute_not_exists`:
        return `*`
      default:
        return ``
    }
  })()
  return base + opAndValue
}

export function convertUrlFiltersToES(
  filters: string | undefined | null,
  join: string | undefined | null = null,
): string[] {
  let esTerms = decodeFilters(filters).flatMap((filter) =>
    !!filter.value ? compact([convertFilterToESQueryString(filter)]) : [],
  )
  if (join && esTerms.length) {
    return [esTerms.join(` ${join.toUpperCase()} `)]
  }
  return esTerms
}

export function addLoaderFilterProperties(
  attribute: string,
  preAppliedFilters?: PredefinedFilter,
): Partial<FilterableColumn> | undefined {
  let defaultValueOperator = preAppliedFilters?.[attribute]
  if (defaultValueOperator) {
    return {
      defaultFilter: true,
      defaultValue: defaultValueOperator.value,
      defaultOperator: defaultValueOperator.operator,
    }
  }
}
/**
 * This function takes the filters from the URL and transforms them into filters that can
 * be used to query the Campaign model.
 * Platform filters are transformed into clid filters. gclid for Google and fbclid for Facebook.
 * @param filters string that comes from the URL
 * @returns string that can be used to query the Campaign model
 */
export function translatePlatformFilter(filters: string | undefined | null) {
  let clidFilters = decodeFilters(filters).map((filter) => {
    if (filter.attribute === `platform`) {
      return { ...filter, attribute: filter.value === `google` ? `gclid` : `fbclid`, value: `*` }
    }
    return filter
  })
  return encodeFilters(clidFilters)

  return filters
}
